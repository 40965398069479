import api from "../api";

export function fetchSchoolsListAction(payload) {
  return api.get(
    `/schools${
      payload?.orderType ? `?order=${payload.orderType}` : "?order=desc"
    }${
      payload?.page && payload?.page > 0 ? `?page=${payload?.page}` : ""
    }${payload?.query ? `&query=${payload.query}` : ""}`
  );
}

export function fetchSchoolsNonPaginatedListAction() {
  return api.get("/schools/list_all");
}

export function fetchCreateSchoolRequest(payload) {
  return api.post(`/schools`, {
    paramObj: payload,
  });
}

export function fetchSchoolUpdateAction(payload) {
  return api.put(`/schools/${payload?.school?.id}`, {
    paramObj: payload,
  });
}

export function deleteSchoolRequest(payload) {
  return api.delet(`/schools/${payload?.school?.id}`, {
    paramObj: payload,
  });
}
